<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="10" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                <ValidationProvider name="Organiation Name" vid="org_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="organiation_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{$t('org_pro.org_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="subCategory.org_id"
                      :options="organizationList"
                      id="organiation_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="category Name" vid="category_id" rules="required|min_value:1">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="category_id"
                    slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                    {{$t('irrigation_config.category_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="subCategory.category_id"
                      :options="categoryList"
                      id="category_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                        <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Sub-Category name" vid="sub_category_name" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="sub_category_name"
                    slot-scope="{ valid, errors }"
                  >
                   <template v-slot:label>
                    {{$t('irrigation_config.sub_category_name')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="sub_category_name"
                      v-model="subCategory.sub_category_name"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="Sub-Category name (bn)" vid="sub_category_name_bn" rules="required">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    label-for="sub_category_name_bn"
                    slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{$t('irrigation_config.sub_category_name_bn')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      id="sub_category_name_bn"
                      v-model="subCategory.sub_category_name_bn"
                      :state="errors[0] ? false : (valid ? true : null)"
                      ></b-form-input>
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { subCategoryStore, subCategoryUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getsubCategoryData()
      Object.freeze(tmp)
      this.subCategory = tmp
    }
    this.getOrganizationlist()
    this.getCategorylist()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      subCategory: {
        org_id: 0,
        category_id: 0,
        sub_category_name: '',
        sub_category_name_bn: ''
      },
      organizationList: [],
      categoryList: []
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    getsubCategoryData () {
      return this.$store.state.IrriConfig.subCategories.find(item => item.id === this.id)
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.subCategory.id) {
        result = await RestApi.putData(irriSchemeServiceBaseUrl, `${subCategoryUpdate}/${this.id}`, this.subCategory)
      } else {
        result = await RestApi.postData(irriSchemeServiceBaseUrl, subCategoryStore, this.subCategory)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getOrganizationlist () {
      const orgList = this.$store.state.commonObj.organizationProfileList
        this.organizationList = orgList.map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
    },
    getCategorylist () {
      RestApi.getData(irriSchemeServiceBaseUrl, 'item-categories/list').then(response => {
        this.categoryList = response.data.data.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.id, text: obj.category_name_bn }
          } else {
            return { value: obj.id, text: obj.category_name }
          }
        })
      })
    }
  }
}
</script>
